<template>
  <div class="">
    <van-nav-bar left-arrow :title="$t('sale.详情')" fixed @click-left="$router.go(-1)" />
    <van-collapse v-model="activeNames" accordion style="margin-top: 55px">
      <div class="splitTitle">{{ $t('sale.基本信息') }}</div>
      <van-collapse-item :title="$t('sale.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('sale.账套') }}</h5>
          <p>{{ ruleForm.orgName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.状态') }}</h5>
          <p>{{ ruleForm.orderStatus | setDict('PUB_WF_STATUS1') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.终结状态') }}</h5>
          <p>{{ ruleForm.finalStatus | setDict('FINAL_STATUS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售合同号') }}</h5>
          <p>{{ ruleForm.contractNo }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.合同类型') }}</h5>
          <p>{{ ruleForm.contractType | setDict('SALE_EXPORT_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.采购来源') }}</h5>
          <p>{{ ruleForm.purchaseSource | setDict('PURCHASE_SOURCE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.合同日期') }}</h5>
          <p>{{ ruleForm.applyDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.客户名称') }}</h5>
          <p>{{ ruleForm.customerName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.客户等级') }}</h5>
          <p>{{ ruleForm.riskLvl | setDict('CUSTOMER_LEVEL') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.国别') }}</h5>
          <p>{{ ruleForm.area }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.销售业务员') }}</h5>
          <p>{{ ruleForm.bizUserName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.业务部门') }}</h5>
          <p>{{ ruleForm.bizDeptName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.生效日期') }}</h5>
          <p>{{ ruleForm.effectiveDate }}</p>
        </div>
        <div class="items" v-if="ruleForm.orgId !== '517730389255520257'">
          <h5>{{ $t('sale.增值税率') }}</h5>
          <p>{{ ruleForm.taxRate | setDict('SALE_VAT_RATE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.币种') }}</h5>
          <p>{{ ruleForm.currency | setDict('CURRENCY_TYPE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.原币金额') }}</h5>
          <p>{{ ruleForm.contractAmount | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.对美元汇率') }}</h5>
          <p>{{ ruleForm.exchangeRateUsd }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.美元金额') }}</h5>
          <p>{{ ruleForm.contractAmountUsd | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.对') + functionalCurrency + $t('sale.汇率') }}</h5>
          <p>{{ ruleForm.exchangeRateRmb }}</p>
        </div>
        <div class="items">
          <h5>{{ functionalCurrency + $t('sale.金额') }}</h5>
          <p>{{ ruleForm.contractAmountRmb | formatAmount }}</p>
        </div>
        <div class="items" v-if="ruleForm.orgId !== '517730389255520257' || ruleForm.contractType === '1'">
          <h5>{{ $t('sale.是否寄售业务') }}</h5>
          <p>{{ ruleForm.isConsignmentBusiness | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.订单数量') }}</h5>
          <p>{{ ruleForm.commodityQuantity | formatAmount(6) }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.利润') }}</h5>
          <p>{{ computedProfitAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.利润率') }}</h5>
          <p>{{ ruleForm.profitRate ? ruleForm.profitRate.toFixed(2)+'%' : '' }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.备注') }}</h5>
          <p>{{ ruleForm.remark }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.性质')" name="2">
        <div class="items">
          <h5>{{ $t('sale.是否赊销') }}</h5>
          <p>{{ ruleForm.isSoldOnCredit | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.赊销天数') }}</h5>
          <p>{{ ruleForm.soldOnCreditDay }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.资金占压利息') }}</h5>
          <p>{{ ruleForm.fundsOccupyInterest }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.年利率') }}</h5>
          <p>{{ ruleForm.annualInterestRate ? ruleForm.annualInterestRate+'%' : '' }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否标准合同') }}</h5>
          <p>{{ ruleForm.isStandardContract | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否定制商品') }}</h5>
          <p>{{ ruleForm.isCustomizeProduct | setDict('PUB_Y_N') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.是否有关税') }}</h5>
          <p>{{ ruleForm.isHasTariff | setDict('PUB_Y_N') }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.合同条款')" name="3">
        <div class="items">
          <h5>{{ $t('sale.价格条款') }}</h5>
          <p>{{ this.ruleForm.priceTerms | setDict('PRICE_TERMS') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.运输方式') }}</h5>
          <p>{{ this.ruleForm.shipmentType | setDict('TYPE_OF_SHIPPING') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.预计装运期') }}</h5>
          <p>{{ this.ruleForm.expectedDeliveryDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.装运港') }}</h5>
          <p>{{ this.ruleForm.shipmentPortName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.目的港') }}</h5>
          <p>{{ this.ruleForm.destinationPortName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.溢短装率') }}</h5>
          <p>{{ this.ruleForm.overloadingRate ? ruleForm.overloadingRate+'%':'' }}</p>
        </div>
        <div class="items" v-if="ruleForm.orgId === '517730389255520257'">
          <h5>{{ $t('sale.价格有效期') }}</h5>
          <p>{{ this.ruleForm.validDate }}</p>
        </div>
        <div class="items" v-if="ruleForm.orgId === '517730389255520257'">
          <h5>{{ $t('sale.工厂完货日期') }}</h5>
          <p>{{ this.ruleForm.deliveryDate }}</p>
        </div>
        <div class="items" v-if="this.ruleForm.orgId === '517730389255520257'">
          <h5>{{ $t('sale.运保费') }}</h5>
          <p>{{ this.ruleForm.shippingAmountUsd | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.商检费') }}</h5>
          <p>{{ this.ruleForm.inspectionFee | formatAmount }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.商品明细')" name="commodity">
        <div v-if="!ruleForm.commodityList || !ruleForm.commodityList.length">{{ $t('sale.无') }}</div>
        <div v-for="(item, index) in ruleForm.commodityList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.商品名称') }}</h5>
            <p>{{ item.materialName }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('sale.客户信用')" name="credit">
        <customerCredit :supplierId="ruleForm.customerId" :orgId="ruleForm.orgId"/>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.附件') }}</div>
      <van-collapse-item :title="$t('sale.附件信息')" name="4">
        <div v-if="!attachmentList.length">{{ $t('sale.无') }}</div>
        <div v-for="(item, index) in this.attachmentList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.附件名称') }}</h5>
            <p>{{ item.attachmentName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.操作') }}</h5>
            <p>
              <van-icon name="eye-o" color="#19d2fe" size="20" @click="handleFileView(item.id,item.attachmentName)" />
              <!--              <a href="#" @click="handleFileView(item.id,item.attachmentName)">{{ $t('pur.预览') }}</a>-->
              <!--              <a href="#" @click="downloadAttachment(item.id,item.attachmentName)" style="margin-left: 7px">{{ $t('pur.下载') }}</a>-->
            </p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传人') }}</h5>
            <p>{{ item.createName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.上传时间') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle" v-show="false">{{ $t('sale.版本历史') }}</div>
      <van-collapse-item :title="$t('sale.版本历史')" name="25" v-show="false">
        <div v-if="!historyList.length">无</div>
        <div v-for="(item, index) in this.historyList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('sale.合同号') }}</h5>
            <p>{{ item.contractNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.版本号') }}</h5>
            <p>{{ item.version }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.销售客户') }}</h5>
            <p>{{ item.customerName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.数量') }}</h5>
            <p>{{ item.commodityQuantity }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.含税金额') }}</h5>
            <p>{{ item.contractAmount }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.状态') }}</h5>
            <p>{{ item.orderStatus | setDict('PUB_WF_STATUS1') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.业务员') }}</h5>
            <p>{{ item.bizUserName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.生效日期') }}</h5>
            <p>{{ item.effectiveDate }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.收款方式') }}</h5>
            <p>{{ item.method }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.制单人') }}</h5>
            <p>{{ item.createUserName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.合同类型') }}</h5>
            <p>{{ item.contractType | setDict('SALE_EXPORT_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.账套') }}</h5>
            <p>{{ item.orgName }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.归档状态') }}</h5>
            <p>{{ item.fileStatus | setDict('ARCHIVING_STATUS') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.终结状态') }}</h5>
            <p>{{ item.finalStatus | setDict('FINAL_STATUS') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('sale.制单日期') }}</h5>
            <p>{{ item.createTime }}</p>
          </div>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('sale.系统信息') }}</div>
      <van-collapse-item :title="$t('sale.系统信息')" name="5">
        <div class="items">
          <h5>{{ $t('sale.制单日期') }}</h5>
          <p>{{ this.ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.制单人') }}</h5>
          <p>{{ this.ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('sale.修改时间') }}</h5>
          <p>{{ this.ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="ruleForm.processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import util from '../../../libs/util';
import myHistoryPanel from '@/views/business/components/myHistoryPanel';
import myApproval from '@/views/business/components/myApproval';
import customerCredit from '../customerCredit'

export default {
  components: {
    myHistoryPanel,
    myApproval,
    customerCredit
  },
  data() {
    return {
      // 销售增值税率
      taxRate: '',
      // 采购增值税率
      purTaxRate: '',
      // 退税率
      taxRebateRate: '',
      // 销售净额（扣除佣金及海运费）  销售本位币-海运费合计-佣金合计
      saleNetAmount: 0,
      // 采购含税金额(本位币)
      purAmountRmbIncludeTax: 0,
      // 采购不含税金额(本位币)
      purAmountRmbExcludeTax: 0,
      // 增值税进项税额
      purTaxAmount: 0,
      // 海运费合计
      seaFreightFeeTotal: 0,
      // 国内运费合计
      domesticFreightFeeTotal: 0,
      // 卸货费合计
      unloadingFeeTotal: 0,
      // 港杂费合计
      portChargesFeeTotal: 0,
      // 报关费数量合计
      customsFeeTotal: 0,
      // 内装费合计
      interiorDecorationFeeTotal: 0,
      // 仓储费
      storageFeeTotal: 0,
      // 堆存费
      stowageChargesFeeTotal: 0,
      // 加固费
      reinforcementFeeTotal: 0,
      // THC 费用1
      thcOneFeeTotal: 0,
      // THC 费用2
      thcTwoFeeTotal: 0,
      // THC 费用3
      thcThreeFeeTotal: 0,
      // 熏蒸费
      fumigationFeeTotal: 0,
      // 订舱费
      bookingFeeTotal: 0,
      // 商检费
      inspectionFeeTotal: 0,
      // 单证费
      documentFeeTotal: 0,
      // 铅封费
      sealFeeTotal: 0,
      // 低硫附加费
      lowSulfurSurchargeFeeTotal: 0,
      // ORC费用单位
      orcFeeTotal: 0,
      // ISPS费
      ispsFeeTotal: 0,
      // 报关续柜费
      customsRenewalFeeTotal: 0,
      // 港口建设费
      portConstructionFeeTotal: 0,
      // 洋山港/太平洋港费用单位
      yangshanAndPacificPortFeeTotal: 0,
      // 运抵费
      arrivalFeeTotal: 0,
      // AMS/ENS（欧美航线加收）EBS/CIC (东南亚航线收取）费用
      amsEnsEbsCicFeeTotal: 0,
      // 包干费（仅限散杂货填写）
      lumpFeeTotal: 0,
      // 额外费用
      extraFeeTotal: 0,
      // 空运费
      airFreightFeeTotal: 0,
      // 海运保险费
      marineInsuranceTotal: 0,
      // 中信保保费率
      citicInsurancePremiumTotal: 0,
      // 占压利息货款
      paymentOccupyInterestTotal: 0,
      // 占压利息费用
      feeOccupyInterest: 0,
      // 占压利息费用合计
      feeOccupyInterestTotal: 0,
      // 佣金合计
      commissionTotal: 0,
      // 印花税合计
      stampTaxTotal: 0,
      // 出口关税
      exportTariffAmount: 0,
      // 成本合计
      costAmount: 0,
      // 退税额
      taxRefundTotal: 0,
      // 毛利润
      grossProfit: 0,
      // 毛利率
      grossProfitRate: 0,
      // 退税前净利润
      profitBeforeTaxRefund: 0,
      // 退税后净利润
      profitAfterTaxRefund: 0,
      // 退税后相对净利润率
      relativeProfitMarginAfterTaxRefund: 0,
      // 销售本位币税额
      saleVatAmountRmb: 0,
      // 银行费用
      bankFeeTotal: 0,
      functionalCurrency: '',
      attachmentList: [],
      historyList: [],
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: '',
      task: '',
      personId: '',
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
      id: '',
      key: '',
      title: '',
      detail: null,
      active: 0,
      businessKey: '',
      woCode: 'saleExportContract',
      processInstanceName: '境外销售合同',
      mappingId: '',
      taskInstanceId: '',
      currentNode: '',
      variables: {},
      taskType: '',
      ruleForm: {
        id: '',
        contractNo: '',
        finalStatus: 'NOT_END',
        applyDate: '',
        contractType: '',
        customerId: '',
        customerName: '',
        bizUserId: '',
        bizUserName: '',
        bizDeptId: '',
        bizDeptName: '',
        assistantId: '',
        assistantName: '',
        area: '',
        ecOrderSource: '',
        customerContractNo: '',
        effectiveDate: '',
        majorSectionCode: '',
        majorSection: '',
        isOrder: '',
        isWholeOrderSale: '',
        remark: '',
        priceTerms: '',
        shipmentType: '',
        shippingAmountUsd: 0,
        insuranceCoefficient: 110,
        insuranceRate: 0.12,
        insuranceAmountUsd: 0,
        shipmentPortCode: '',
        shipmentPortName: '',
        destinationPortCode: '',
        destinationPortName: '',
        overloadingRate: 0,
        latestShipmentDate: '',
        estimatedDepartureDate: '',
        originCountry: '',
        partialShipment: '',
        transhipment: '',
        currency: '',
        contractAmount: 0,
        exchangeRateRmb: 0,
        contractAmountRmb: 0,
        exchangeRateUsd: 0,
        contractAmountUsd: 0,
        isUse: 'N',
        version: 0,
        isOldVersion: 'N',
        originalContractNo: '',
        orgId: '',
        orgName: '',
        deptId: '',
        orderStatus: '2',
        processInstanceId: '',
        createUserName: '',
        paymentList: [],
        commodityList: [],
        expectedDeliveryDate: '',
        purchaseSource: '',
        commodityQuantity: 0,
        domesticFreight: 0,
        profitRate: 0,
        isSoldOnCredit: '',
        soldOnCreditDay: 0,
        fundsOccupyInterest: '',
        annualInterestRate: 5,
        isMajorContract: 'N',
        isPayCommission: 'N',
        isIronOreBusiness: 'N',
        isFundAdvanceBusiness: 'N',
        isRiskBusiness: 'N',
        riskLvl: '',
        isCustomizeProduct: 'N',
        isHasTariff: 'N',
        isStandardContract: 'Y',
        isConsignmentBusiness: 'N',
        overLoadingRate: 0,
        profit: {},
        bidId: null,
        bidCode: null,
        bomId: null,
        bomCode: null,
      }
    };
  },
  computed: {
    computedProfitAmount() {
      // 原逻辑位于 [overseas-web]/src/views/business/views/sale/export/tabs/overseasProfit.vue (2022-05-30)
      let taxRefundTotal = 0
      let purAmountRmbIncludeTax = 0
      this.ruleForm.commodityList.forEach(it => {
        taxRefundTotal += (Number(((it.purAmount || 0) * (it.purExchangeRateRmb || 0)).toFixed(2)) / (1 + (it.taxRebateRate || 0) * 0.01)) * ((it.taxRebateRate || 0) * 0.01)
        purAmountRmbIncludeTax += Number((Number(it.purAmountRmb) || 0).toFixed(2))
      })

      let citicInsurancePremiumTotal = (this.ruleForm.contractAmountRmb || 0) * ((this.ruleForm.profit.citicInsurancePremiumRatio || 0) * 0.01) * ((this.ruleForm.profit.citicInsurancePremiumRate || 0) * 0.01)
      let paymentOccupyInterestTotal = (this.ruleForm.profit.paymentOccupyAmount * (this.ruleForm.profit.paymentOccupyDays || 0) * ((this.ruleForm.annualInterestRate || 0) * 0.01)) / 365
      let feeOccupyInterestTotal = (this.ruleForm.profit.feeOccupyAmount * (this.ruleForm.profit.feeOccupyDays || 0) * ((this.ruleForm.annualInterestRate || 0) * 0.01)) / 365
      let bankFeeTotal = this.ruleForm.contractAmountRmb * (this.ruleForm.profit.bankFee || 0) * 0.01
      let commissionTotal = (this.ruleForm.contractAmountRmb || 0) * (this.ruleForm.profit.commissionRate || 0) * 0.01
      let stampTaxTotal = ((this.ruleForm.contractAmountRmb || 0) + purAmountRmbIncludeTax) * (this.ruleForm.profit.stampTaxRate || 0) * 0.01
      let exportTariffAmount = (this.ruleForm.profit.exportTariffRate || 0) * (this.ruleForm.contractAmount || 0) * 0.01

      let costAmount =
        citicInsurancePremiumTotal +
        (this.ruleForm.profit.citicInsuranceFinancingTotal || 0) +
        paymentOccupyInterestTotal +
        feeOccupyInterestTotal +
        bankFeeTotal +
        commissionTotal +
        stampTaxTotal +
        (this.ruleForm.profit.saleVatAmount || 0) +
        exportTariffAmount +
        this.ruleForm.profit.feeOccupyAmount +
        purAmountRmbIncludeTax
      return ((this.ruleForm.contractAmountRmb || 0) - costAmount + taxRefundTotal).toFixed(2)
    }
  },
  props: {},
  methods: {
    /**
     * 根据文件id和文件名生成预览链接
     * @param id 附件id
     * @param fileName 附件名
     * @returns {string}
     */
    handleFileView (id, fileName) {
      this.$FilePrvwUtils.openFile(id,fileName);
    },
    downloadAttachment(id, fileName) {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/download",
        data: {
          "entity": {
            "id": id
          }
        },
        responseType: "arraybuffer"
      }).then(res => {
        // 成功回调
        var headers = res.headers;
        var blob = new Blob([res.data], {
          type: headers["content-type"]
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
    },
    getAttachmentList() {
      this.$myHttp({
        method: "post",
        url: "/microarch/sys/sysAttchement/list",
        data: {
          "entity": {
            "bizId": this.ruleForm.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.attachmentList = backData;
        }
      });
    },
    findHistory() {
      const url = '/microarch/pur/purDomesticTradeHeader/list';
      let fixedQuery = [
        {
          field: 'originalContractNo',
          opt: '=',
          value: this.ruleForm.originalContractNo,
          assemble: 'and'
        },
        {
          field: 'isOldVersion',
          opt: '=',
          value: 'Y',
          assemble: 'and'
        }
      ];
      this.$myHttp({
        method: 'POST',
        url: url,
        data: {
          where: fixedQuery
        }
      }).then(res => {
        var data = res.data.rows;
        this.historyList = data;
      });
    },
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.$myHttp({
        method: 'post',
        url: '/microarch/activiti/mapping/findBywoCodeAndOrgDept',
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === '2000') {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: 'error',
            duration: 1500
          });
        }
      });
    },
    backfilleditData(id) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/sale/contract/exportContract/view',
        data: {
          entity: {
            id: id
          }
        }
      }).then(res => {
        // 成功回调方法
        var data = res.data.entity;
        if (data) {
          this.ruleForm = res.data.entity;
          this.calculateProfit();
          this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
          this.variables.activitiData = res.data.entity;
          this.getProcessMappingId();
          this.getAttachmentList();
          this.findHistory();
          this.$refs.historyPanel.getData(this.ruleForm.processInstanceId);
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    calculateProfit () {
      let _this = this;
      _this.purTaxRate = Array.from(new Set(this.ruleForm.commodityList.map(it => (it.purTaxRate || 0) + '%'))).join(',');
      _this.taxRebateRate = Array.from(new Set(this.ruleForm.commodityList.map(it => (it.taxRebateRate || 0) + '%'))).join(',');
      _this.purAmountRmbIncludeTax = 0;
      _this.purTaxAmount = 0;
      _this.purAmountRmbExcludeTax = 0;
      _this.saleVatAmountRmb = 0;
      this.ruleForm.commodityList.forEach(it => {
        _this.purAmountRmbIncludeTax += Number((Number(it.purAmountRmb) || 0).toFixed(2));
        _this.purTaxAmount += Number(((it.purTaxAmount || 0) * (it.purExchangeRateRmb || 0)).toFixed(2));
        _this.purAmountRmbExcludeTax += Number(((it.purAmountExcludeTax || 0) * (it.purExchangeRateRmb || 0)).toFixed(2));
        _this.saleVatAmountRmb += Number(((it.cifTaxAmount || 0) * (this.ruleForm.exchangeRateRmb || 0)).toFixed(2));
      });
      this.ruleForm.profit.paymentOccupyAmount = this.purAmountRmbIncludeTax;
      this.ruleForm.profit.feeOccupyAmount = this.feeOccupyInterest;
      _this.calculateAllRow();
    },
    calculateAllRow () {
      // 海运费
      this.seaFreightFeeTotal = (this.ruleForm.profit.seaFreightFeeQuantity || 0) * (this.ruleForm.profit.seaFreightFeePrice || 0) * (this.ruleForm.profit.seaFreightFeeExchangeRate || 0);
      // 国内运费合计
      this.domesticFreightFeeTotal = (this.ruleForm.profit.domesticFreightFeeQuantity || 0) * (this.ruleForm.profit.domesticFreightFeePrice || 0) * (this.ruleForm.profit.domesticFreightFeeExchangeRate || 0);
      // 卸货费合计
      this.unloadingFeeTotal = (this.ruleForm.profit.unloadingFeeQuantity || 0) * (this.ruleForm.profit.unloadingFeePrice || 0) * (this.ruleForm.profit.unloadingFeeExchangeRate || 0);
      // 港杂费合计
      this.portChargesFeeTotal = (this.ruleForm.profit.portChargesFeeQuantity || 0) * (this.ruleForm.profit.portChargesFeePrice || 0) * (this.ruleForm.profit.portChargesFeeExchangeRate || 0);
      // 报关费数量合计
      this.customsFeeTotal = (this.ruleForm.profit.customsFeeQuantity || 0) * (this.ruleForm.profit.customsFeePrice || 0) * (this.ruleForm.profit.customsFeeExchangeRate || 0);
      // 内装费合计
      this.interiorDecorationFeeTotal = (this.ruleForm.profit.interiorDecorationFeeQuantity || 0) * (this.ruleForm.profit.interiorDecorationFeePrice || 0) * (this.ruleForm.profit.interiorDecorationFeeExchangeRate || 0);
      // 仓储费
      this.storageFeeTotal = (this.ruleForm.profit.storageFeeQuantity || 0) * (this.ruleForm.profit.storageFeePrice || 0) * (this.ruleForm.profit.storageFeeExchangeRate || 0);
      // 堆存费
      this.stowageChargesFeeTotal = (this.ruleForm.profit.stowageChargesFeeQuantity || 0) * (this.ruleForm.profit.stowageChargesFeePrice || 0) * (this.ruleForm.profit.stowageChargesFeeExchangeRate || 0);
      // 加固费
      this.reinforcementFeeTotal = (this.ruleForm.profit.reinforcementFeeQuantity || 0) * (this.ruleForm.profit.reinforcementFeePrice || 0) * (this.ruleForm.profit.reinforcementFeeExchangeRate || 0);
      // THC 费用1
      this.thcOneFeeTotal = (this.ruleForm.profit.thcOneFeeQuantity || 0) * (this.ruleForm.profit.thcOneFeePrice || 0) * (this.ruleForm.profit.thcOneFeeExchangeRate || 0);
      // THC 费用2
      this.thcTwoFeeTotal = (this.ruleForm.profit.thcTwoFeeQuantity || 0) * (this.ruleForm.profit.thcTwoFeePrice || 0) * (this.ruleForm.profit.thcTwoFeeExchangeRate || 0);
      // THC 费用3
      this.thcThreeFeeTotal = (this.ruleForm.profit.thcThreeFeeQuantity || 0) * (this.ruleForm.profit.thcThreeFeePrice || 0) * (this.ruleForm.profit.thcThreeFeeExchangeRate || 0);
      // 熏蒸费
      this.fumigationFeeTotal = (this.ruleForm.profit.fumigationFeeQuantity || 0) * (this.ruleForm.profit.fumigationFeePrice || 0) * (this.ruleForm.profit.fumigationFeeExchangeRate || 0);
      // 订舱费
      this.bookingFeeTotal = (this.ruleForm.profit.bookingFeeQuantity || 0) * (this.ruleForm.profit.bookingFeePrice || 0) * (this.ruleForm.profit.bookingFeeExchangeRate || 0);
      // 商检费
      this.inspectionFeeTotal = (this.ruleForm.profit.inspectionFeeQuantity || 0) * (this.ruleForm.profit.inspectionFeePrice || 0) * (this.ruleForm.profit.inspectionFeeExchangeRate || 0);
      // 单证费
      this.documentFeeTotal = (this.ruleForm.profit.documentFeeQuantity || 0) * (this.ruleForm.profit.documentFeePrice || 0) * (this.ruleForm.profit.documentFeeExchangeRate || 0);
      // 铅封费
      this.sealFeeTotal = (this.ruleForm.profit.sealFeeQuantity || 0) * (this.ruleForm.profit.sealFeePrice || 0) * (this.ruleForm.profit.sealFeeExchangeRate || 0);
      // 低硫附加费
      this.lowSulfurSurchargeFeeTotal = (this.ruleForm.profit.lowSulfurSurchargeFeeQuantity || 0) * (this.ruleForm.profit.lowSulfurSurchargeFeePrice || 0) * (this.ruleForm.profit.lowSulfurSurchargeFeeExchangeRate || 0);
      // ORC费用单位
      this.orcFeeTotal = (this.ruleForm.profit.orcFeeQuantity || 0) * (this.ruleForm.profit.orcFeePrice || 0) * (this.ruleForm.profit.orcFeeExchangeRate || 0);
      // ISPS费
      this.ispsFeeTotal = (this.ruleForm.profit.ispsFeeQuantity || 0) * (this.ruleForm.profit.ispsFeePrice || 0) * (this.ruleForm.profit.ispsFeeExchangeRate || 0);
      // 报关续柜费
      this.customsRenewalFeeTotal = (this.ruleForm.profit.customsRenewalFeeQuantity || 0) * (this.ruleForm.profit.customsRenewalFeePrice || 0) * (this.ruleForm.profit.customsRenewalFeeExchangeRate || 0);
      // 港口建设费
      this.portConstructionFeeTotal = (this.ruleForm.profit.portConstructionFeeQuantity || 0) * (this.ruleForm.profit.portConstructionFeePrice || 0) * (this.ruleForm.profit.portConstructionFeeExchangeRate || 0);
      // 洋山港/太平洋港费用单位
      this.yangshanAndPacificPortFeeTotal = (this.ruleForm.profit.yangshanAndPacificPortFeeQuantity || 0) * (this.ruleForm.profit.yangshanAndPacificPortFeePrice || 0) * (this.ruleForm.profit.yangshanAndPacificPortFeeExchangeRate || 0);
      // 运抵费
      this.arrivalFeeTotal = (this.ruleForm.profit.arrivalFeeQuantity || 0) * (this.ruleForm.profit.arrivalFeePrice || 0) * (this.ruleForm.profit.arrivalFeeExchangeRate || 0);
      // AMS/ENS（欧美航线加收）EBS/CIC (东南亚航线收取）费用
      this.amsEnsEbsCicFeeTotal = (this.ruleForm.profit.amsEnsEbsCicFeeQuantity || 0) * (this.ruleForm.profit.amsEnsEbsCicFeePrice || 0) * (this.ruleForm.profit.amsEnsEbsCicFeeExchangeRate || 0);
      // 包干费（仅限散杂货填写）
      this.lumpFeeTotal = (this.ruleForm.profit.lumpFeeQuantity || 0) * (this.ruleForm.profit.lumpFeePrice || 0) * (this.ruleForm.profit.lumpFeeExchangeRate || 0);
      // 额外费用
      this.extraFeeTotal = (this.ruleForm.profit.extraFeeQuantity || 0) * (this.ruleForm.profit.extraFeePrice || 0) * (this.ruleForm.profit.extraFeeExchangeRate || 0);
      // 空运费
      this.airFreightFeeTotal = (this.ruleForm.profit.airFreightFeeQuantity || 0) * (this.ruleForm.profit.airFreightFeePrice || 0) * (this.ruleForm.profit.airFreightFeeExchangeRate || 0);
      // 海运保险费 销售本位币总金额*保险费率*1.1
      this.marineInsuranceTotal = (this.ruleForm.contractAmountRmb || 0) * ((this.ruleForm.profit.marineInsuranceRate || 0) * 0.01) * 1.1;
      // 中信保保费  销售本位币总金额 * 中信保保费率 * 投保金额占总金额比例
      this.citicInsurancePremiumTotal = (this.ruleForm.contractAmountRmb || 0) * ((this.ruleForm.profit.citicInsurancePremiumRatio || 0) * 0.01) * ((this.ruleForm.profit.citicInsurancePremiumRate || 0) * 0.01);
      // 占压利息货款合计 = 工厂采购（含税总价）* 天数 * 年利率 / 365
      this.paymentOccupyInterestTotal = this.ruleForm.profit.paymentOccupyAmount * (this.ruleForm.profit.paymentOccupyDays || 0) * ((this.ruleForm.annualInterestRate || 0) * 0.01) / 365;
      // 占压利息费用 海运费+。。。+ 海运保险费
      this.feeOccupyInterest = this.seaFreightFeeTotal + this.domesticFreightFeeTotal + this.unloadingFeeTotal + this.portChargesFeeTotal + this.customsFeeTotal + this.interiorDecorationFeeTotal +
        this.storageFeeTotal + this.stowageChargesFeeTotal + this.reinforcementFeeTotal + this.thcOneFeeTotal + this.thcTwoFeeTotal + this.thcThreeFeeTotal +
        this.fumigationFeeTotal + this.bookingFeeTotal + this.inspectionFeeTotal + this.documentFeeTotal + this.sealFeeTotal + this.lowSulfurSurchargeFeeTotal +
        this.orcFeeTotal + this.ispsFeeTotal + this.customsRenewalFeeTotal + this.portConstructionFeeTotal + this.yangshanAndPacificPortFeeTotal + this.arrivalFeeTotal +
        this.amsEnsEbsCicFeeTotal + this.lumpFeeTotal + this.extraFeeTotal + this.airFreightFeeTotal + this.marineInsuranceTotal;
      // 占压利息费用合计 = 工厂采购（含税总价）* 天数 * 0.066 / 360
      this.feeOccupyInterestTotal = this.ruleForm.profit.feeOccupyAmount * (this.ruleForm.profit.feeOccupyDays || 0) * ((this.ruleForm.annualInterestRate || 0) * 0.01) / 365;
      // 佣金合计  = 销售合同本位币金额 * 佣金比例
      this.commissionTotal = (this.ruleForm.contractAmountRmb || 0) * (this.ruleForm.profit.commissionRate || 0) * 0.01;
      // 印花税合计 = 销售合同本位币金额 + 工厂采购（含税总价）
      this.stampTaxTotal = ((this.ruleForm.contractAmountRmb || 0) + (this.purAmountRmbIncludeTax)) * (this.ruleForm.profit.stampTaxRate || 0) * 0.01;
      // 销项税合计 =（销售本币总金额-采购含税总金额）*增值税率/（1+增值税率） = 销售本位币税额 - 采购本位币税额
      // this.saleTaxAmount = this.saleVatAmountRmb - this.purTaxAmount;
      // 出口关税 = 合同原币金额 * 关税税率
      this.exportTariffAmount = (this.ruleForm.profit.exportTariffRate || 0) * (this.ruleForm.contractAmount || 0) * 0.01;
      // 银行费用合计
      this.bankFeeTotal = this.ruleForm.contractAmountRmb * (this.ruleForm.profit.bankFee || 0) * 0.01;
      // 成本合计 合计(从海运费+...+出口关税)
      this.costAmount = this.citicInsurancePremiumTotal + (this.ruleForm.profit.citicInsuranceFinancingTotal || 0) + this.paymentOccupyInterestTotal + this.feeOccupyInterestTotal +
        this.bankFeeTotal + this.commissionTotal + this.stampTaxTotal + (this.ruleForm.profit.saleVatAmount || 0) + this.exportTariffAmount + this.feeOccupyInterest +
        this.purAmountRmbIncludeTax;

      // 退税额
      this.taxRefundTotal = 0;
      this.ruleForm.commodityList.forEach(it => {
        let purAmountRmbExcludeTax = Number(((it.purAmount || 0) * (it.purExchangeRateRmb || 0)).toFixed(2));
        this.taxRefundTotal += purAmountRmbExcludeTax / (1 + ((it.taxRebateRate || 0) * 0.01)) * ((it.taxRebateRate || 0) * 0.01);
      });
      // 毛利润
      this.grossProfit = (this.ruleForm.profit.seaFreightFeeQuantity || 0) * (this.ruleForm.profit.seaFreightFeePrice || 0) * (this.ruleForm.profit.seaFreightFeeExchangeRate || 0);
      // 毛利率
      this.grossProfitRate = (this.ruleForm.profit.seaFreightFeeQuantity || 0) * (this.ruleForm.profit.seaFreightFeePrice || 0) * (this.ruleForm.profit.seaFreightFeeExchangeRate || 0);
      // 退税前净利润 = 合同本位币金额 - 成本金额
      this.profitBeforeTaxRefund = (this.ruleForm.contractAmountRmb || 0) - this.costAmount;
      // 退税后净利润 = 退税前净利润 + 退税额
      this.profitAfterTaxRefund = this.profitBeforeTaxRefund + this.taxRefundTotal;
      // 退税后相对净利润率
      let contractAmountRmb = (this.ruleForm.contractAmountRmb || 0);
      if (contractAmountRmb === 0) {
        this.relativeProfitMarginAfterTaxRefund = 0;
      } else {
        this.relativeProfitMarginAfterTaxRefund = this.profitAfterTaxRefund / (this.ruleForm.contractAmountRmb || 0) * 100;
      }
      // 销售净额（扣除佣金及海运费）  销售本位币-海运费合计-佣金合计
      this.saleNetAmount = this.ruleForm.contractAmountRmb - this.seaFreightFeeTotal - this.commissionTotal;
      // 毛利 = 销售净额-成本合计＋银行费用＋占压利息
      this.grossProfit = this.saleNetAmount - this.costAmount + (this.ruleForm.profit.bankFee || 0) + this.paymentOccupyInterestTotal + this.feeOccupyInterestTotal;
      // 毛利润
      this.grossProfitRate = Number((this.grossProfit / this.saleNetAmount * 100).toFixed(2));

      this.ruleForm.profitRate = this.relativeProfitMarginAfterTaxRefund;
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === 'beforeAddsign') {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === 'afterAddsign') {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === 'endProcess') {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === 'repelProcess') {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem('userName'),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: this.$t('sale.数据校验失败'),
          type: 'warning'
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/sale/contract/exportContract/start',
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: this.$t('sale.流程发起异常'),
              type: 'warning'
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.contractNo, this.processInstanceName);
      this.$myHttp({
        method: 'post',
        url: '/microarch/sale/contract/exportContract/submit',
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: 'warning'
            });
          }
        })
        .catch(_ => {});
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t('myApprovalBtn.confirmRevocationProcess'), {
          cancelButtonClass: 'sysBackBtn'
        })
        .then(_ => {
          this.$myHttp({
            method: 'post',
            url: '/microarch/sale/contract/exportContract/undoProcess',
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: this.$t('sale.撤销成功'),
                  type: 'success'
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: 'warning'
                });
              }
            })
            .catch(_ => {});
        })
        .catch(_ => {});
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: 'POST',
        url: '/microarch/sale/contract/exportContract/deleteProcess',
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = '';
          this.$refs.demoTemperatureEdit.processInstanceId = '';
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {});
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    this.functionalCurrency = util.getCurrencyName(this.ruleForm.orgId);
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
      }
    }
    if (this.ruleForm.id) {
      this.backfilleditData(this.ruleForm.id);
    }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
