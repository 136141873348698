<template>
  <div>
    <div class="items">
      <h5>{{ $t('sale.客户名称') }}</h5>
      <p>{{ customerHeader.customerName }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.信用等级') }}</h5>
      <p>{{ customerHeader.customerLevel }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.信用额度') }}</h5>
      <p>{{ customerCredit.creditLimit }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.信用账期(天)') }}</h5>
      <p>{{ customerCredit.proposalCreditArrearsPeriod }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.已用额度') }}</h5>
      <p>{{ customerCredit.useCreditLimit }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.可用额度') }}</h5>
      <p>{{ customerCredit.availableCreditLimit }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.超期应收') }}</h5>
      <p>{{ customerCredit.overdueReceivables }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.是否逾期') }}</h5>
      <p>{{ customerCredit.overdueFlag | setDict('PUB_Y_N') }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.逾期次数小计') }}</h5>
      <p>{{ customerCredit.overdueCountSum }}</p>
    </div>
    <div class="items">
      <h5>{{ $t('sale.逾期金额小计') }}</h5>
      <p>{{ customerCredit.overdueAmountSum }}</p>
    </div>
    <van-divider v-if="sinosureCreditApplicationList.length" dashed :style="{ borderColor: '#333' }" />
    <div v-for="(item, index) in sinosureCreditApplicationList" :key="index" class="detailLine">
      <div class="items">
        <h5>{{ $t('sale.序号') }}</h5>
        <p>{{ index + 1 }}</p>
      </div>
      <div class="items">
        <h5>{{ $t('sale.批复额度USD（万元）') }}</h5>
        <p>{{ item.replyAmount / 10000 }}</p>
      </div>
      <div class="items">
        <h5>{{ $t('sale.信用期限') }}</h5>
        <p>{{ item.deadline }}</p>
      </div>
      <div v-if="item.insuranceRateRejection" class="items">
        <h5>{{ $t('sale.拒收风险赔偿比例') }}</h5>
        <p>{{ item.insuranceRateRejection }}%</p>
      </div>
      <div v-if="item.insuranceRateOtherRisks" class="items">
        <h5>{{ $t('sale.其他商业风险赔偿比例') }}</h5>
        <p>{{ item.insuranceRateOtherRisks }}%</p>
      </div>
      <van-divider dashed :style="{ borderColor: '#333' }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    supplierId: { type: String, default: '' },
    orgId: { type: String, default: JSON.parse(localStorage.getItem('userInfo'))['companyId'] }
  },
  data() {
    return {
      customerHeader: {}
    };
  },
  computed: {
    customerCredit() {
      if (!this.customerHeader || !this.customerHeader.customerOrgCreditList) {
        return {};
      }
      return this.customerHeader.customerOrgCreditList.find(item => item.orgId === this.orgId) || {};
    },
    sinosureCreditApplicationList() {
      return (this.customerHeader.sinosureCreditApplicationList || []).filter(item => item.orgId === this.orgId);
    }
  },
  mounted() {
    this.getCustomerCredit();
  },
  methods: {
    getCustomerCredit(id = this.supplierId, orgId = this.orgId) {
      const data = { entity: { id: id, currentOrgId: orgId } };
      this.$myHttp.post('/microarch/customer/customerHeader/view', data).then(response => {
        this.customerHeader = response.data.entity;
      });
    }
  }
};
</script>
